import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Portfolio from '../views/Portfolio.vue'
import contact from "@/views/Contact";

function lazyLoad(view){
  return() => import(`@/views/${view}.vue`)
}

const routes = [
  {
    path: '/',
    name: 'home',
    props: true,
    component: HomeView,
    meta: {
      title: "Van Loo Keukens | Met precisie en vakmanschap"
    }
  },
  {
    path: '/projecten',
    name: 'Projecten',
    props: true,
    component: lazyLoad('Portfolio'),
    meta: {
      title: "Projecten | Van Loo Keukens"
    }
  },
  {
    path: '/keuken-oldebroek-van-loo',
    name: 'pf-item1',
    props: true,
    component: lazyLoad('projecten/OldebroekKeuken'),
    meta: {
      title: "Keuken in oldebroek | Van Loo Keukens"
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    props: true,
    component: contact,
    meta: {
      title: "Contact | Van Loo Keukens"
    }
  },
  {
    path: '/over-mij',
    name: 'About',
    props: true,
    component: lazyLoad('About'),
    meta: {
      title: "Over mij | Van Loo Keukens"
    }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})


router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  next();
})

export default router
