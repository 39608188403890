<template>
  <div class="landing">
    <div class="introduction-image">
      <img src="/images/bas-van-loo-foto.webp" alt="Keuken Bas van Loo">
      <div class="image-overlay" data-aos="fade-left" data-aos-duration="1200">
        <h1>Bas van Loo</h1>
        <p>"Vanaf 2006 is Bas van Loo bezig met het maken van de mooiste interieur opdrachten. Van op maat
          gemaakte meubels tot complete keukens. De mogelijkheden zijn eindloos."</p>
      </div>
    </div>
    <section class="text-image" style="margin: 40px 0">
      <div class="container grid-container">
        <div class="img-side hero-image">
          <img src="/images/keuken-bas-van-loo9.webp" alt="Bas Van Loo Keukens">
        </div>
        <div class="img-side hero-image">
          <img src="/images/keuken-bas-van-loo10.webp" alt="Bas Van Loo Keukens">
        </div>
        <div class="img-side hero-image">
          <img src="/images/keuken-bas-van-loo11.webp" alt="Bas Van Loo Keukens">
        </div>
      </div>
    </section>
    <section class="zekerheden">
      <div class="container">
        <b>Diensten</b>
        <h3>Mijn zekerheden</h3>
        <div class="items-grid">
          <div class="single-item">
            <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M25.25 5H22.475L21.7 1.9C21.425 0.7875 20.425 0 19.275 0H8.725C7.575 0 6.575 0.775 6.3 1.9L5.525 5H2.75C1.375 5 0.25 6.125 0.25 7.5V21.25C0.25 22.625 1.375 23.75 2.75 23.75H25.25C26.625 23.75 27.75 22.625 27.75 21.25V7.5C27.75 6.125 26.625 5 25.25 5ZM8.725 2.5H19.275L19.9 5H8.1L8.725 2.5ZM25.25 7.5V11.25H19C18.3125 11.25 17.75 11.8125 17.75 12.5V13.75H10.25V12.5C10.25 11.8125 9.6875 11.25 9 11.25H2.75V7.5H25.25ZM2.75 21.25V13.75H7.75V15C7.75 15.6875 8.3125 16.25 9 16.25H12.75V17.5C12.75 18.1875 13.3125 18.75 14 18.75C14.6875 18.75 15.25 18.1875 15.25 17.5V16.25H19C19.6875 16.25 20.25 15.6875 20.25 15V13.75H25.25V21.25H2.75Z"
                  fill="#E3D4C2"/>
            </svg>
            <h5>Kwaliteit</h5>
            <p>Ik lever hoogwaardig vakmanschap waarbij ik gebruik maak van duurzame materialen. Jouw project wordt met
              de grootste zorg en precisie uitgevoerd.</p>
          </div>
          <div class="single-item">
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M22.0312 28.125C20.2219 28.125 18.75 26.6531 18.75 24.8438V12.5316C17.0016 11.4169 15.9375 9.49969 15.9375 7.40063C15.9375 5.10376 17.2106 3.02626 19.26 1.97719C19.5516 1.82907 19.8984 1.84126 20.1769 2.01188C20.4553 2.18344 20.625 2.48532 20.625 2.81251V6.86532L22.0312 7.42782L23.4375 6.86532V2.81251C23.4375 2.48532 23.6072 2.18344 23.8856 2.01188C24.1641 1.84126 24.5109 1.82907 24.8025 1.97719C26.8519 3.02719 28.125 5.10376 28.125 7.40157C28.125 9.49969 27.0609 11.4169 25.3125 12.5325V24.8438C25.3125 26.6531 23.8406 28.125 22.0312 28.125ZM18.75 4.74938C18.1528 5.48532 17.8125 6.41344 17.8125 7.40063C17.8125 8.98876 18.6947 10.4278 20.115 11.1553C20.4281 11.3156 20.625 11.6381 20.625 11.9897V24.8438C20.625 25.6191 21.2559 26.25 22.0312 26.25C22.8066 26.25 23.4375 25.6191 23.4375 24.8438V11.9897C23.4375 11.6381 23.6344 11.3156 23.9475 11.1553C25.3678 10.4278 26.25 8.98969 26.25 7.40157C26.25 6.41438 25.9097 5.48626 25.3125 4.75032V7.50001C25.3125 7.88344 25.0791 8.22751 24.7238 8.37094L22.38 9.30844C22.1559 9.39844 21.9075 9.39844 21.6834 9.30844L19.3397 8.37094C18.9834 8.22751 18.75 7.88344 18.75 7.50001V4.74938Z"
                  fill="#E3D4C2"/>
              <path
                  d="M16.0069 28.125H13.9941C13.02 28.125 12.1988 27.3647 12.1247 26.3944L12.0066 24.8616C11.3634 24.6666 10.7409 24.4078 10.1456 24.0891L8.9775 25.0894C8.23781 25.7241 7.12031 25.68 6.43219 24.9928L5.00719 23.5669C4.31906 22.8787 4.27594 21.7603 4.90969 21.0206L5.91 19.8534C5.59125 19.2581 5.3325 18.6356 5.1375 17.9925L3.60563 17.8753C2.63531 17.8022 1.875 16.9809 1.875 16.0069V13.9931C1.875 13.0191 2.63531 12.1978 3.60656 12.1237L5.13844 12.0066C5.33344 11.3625 5.59219 10.74 5.91094 10.1456L4.90969 8.97656C4.27594 8.23781 4.31906 7.12031 5.00813 6.43031L6.43125 5.00625C7.12031 4.31719 8.23875 4.275 8.9775 4.90875L10.1456 5.90906C10.7409 5.59031 11.3634 5.33156 12.0066 5.13656L12.1247 3.60375C12.1988 2.63531 13.02 1.875 13.9931 1.875H15C15.5175 1.875 15.9375 2.29406 15.9375 2.8125C15.9375 3.33094 15.5175 3.75 15 3.75H13.9931L13.8244 5.94469C13.7925 6.35344 13.4991 6.69375 13.1006 6.78562C12.1941 6.99469 11.3316 7.35188 10.5375 7.84969C10.1916 8.06813 9.7425 8.03531 9.43031 7.76625L7.75688 6.33281L6.33375 7.75781L7.76719 9.43031C8.03438 9.74156 8.06719 10.1906 7.84969 10.5375C7.35281 11.3325 6.99469 12.195 6.78563 13.1006C6.69375 13.5 6.3525 13.7934 5.94375 13.8244L3.75 13.9931V16.0069L5.94375 16.1756C6.3525 16.2066 6.69375 16.5009 6.78563 16.8994C6.99469 17.8059 7.35188 18.6684 7.84969 19.4625C8.06719 19.8094 8.03344 20.2584 7.76719 20.5697L6.33375 22.2422L7.75781 23.6672L9.43031 22.2337C9.7425 21.9656 10.1916 21.9328 10.5375 22.1503C11.3325 22.6472 12.195 23.0053 13.1006 23.2144C13.5 23.3072 13.7934 23.6475 13.8244 24.0553L13.9931 26.25H16.0059C16.0453 25.7344 16.4944 25.3491 17.0128 25.3875C17.5294 25.4269 17.9156 25.8778 17.8753 26.3944C17.8013 27.3647 16.98 28.125 16.0069 28.125Z"
                  fill="#E3D4C2"/>
              <path
                  d="M8.4375 15C8.4375 11.7806 10.7381 9.05906 13.9078 8.52844C14.4169 8.4375 14.9025 8.78719 14.9869 9.29812C15.0722 9.80906 14.7281 10.2919 14.2172 10.3772C11.955 10.7569 10.3125 12.7012 10.3125 15C10.3125 18.2372 13.5281 20.4947 16.5628 19.4212C17.0484 19.2478 17.5866 19.5028 17.76 19.9912C17.9325 20.4797 17.6775 21.015 17.1891 21.1875C12.9206 22.6978 8.4375 19.515 8.4375 15Z"
                  fill="#E3D4C2"/>
            </svg>
            <h5>Maatwerk</h5>
            <p>Ik luister naar jouw wensen en vertaal deze naar unieke houten creaties op maat. Of het nu gaat om
              meubels, renovaties of andere projecten, ik zorg ervoor dat het resultaat perfect aansluit bij jouw
              behoeften.</p>
          </div>
          <div class="single-item">
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M12.1875 17.5969V22.5C12.1875 23.0184 12.6075 23.4375 13.125 23.4375H16.875C17.3925 23.4375 17.8125 23.0184 17.8125 22.5V17.5969C19.5394 16.5994 20.625 14.7506 20.625 12.7303C20.625 10.4991 19.3003 8.47688 17.2509 7.57969C16.9603 7.45313 16.6266 7.48032 16.3622 7.65376C16.0969 7.82626 15.9375 8.12157 15.9375 8.43751V12.5456L15 13.0144L14.0625 12.5456V8.43751C14.0625 8.12157 13.9031 7.82626 13.6378 7.65282C13.3725 7.47938 13.0387 7.45313 12.7491 7.57876C10.6997 8.47594 9.375 10.4981 9.375 12.7294C9.375 14.7497 10.4606 16.5984 12.1875 17.5969ZM12.1875 10.2544V13.125C12.1875 13.4803 12.3881 13.8047 12.7059 13.9641L14.5809 14.9016C14.8444 15.0338 15.1556 15.0338 15.42 14.9016L17.295 13.9641C17.6119 13.8047 17.8125 13.4803 17.8125 13.125V10.2544C18.405 10.9256 18.75 11.8003 18.75 12.7303C18.75 14.2172 17.8669 15.5644 16.4991 16.1634C16.1578 16.3116 15.9375 16.6491 15.9375 17.0213V21.5625H14.0625V17.0213C14.0625 16.6491 13.8422 16.3116 13.5009 16.1625C12.1331 15.5644 11.25 14.2163 11.25 12.7294C11.25 11.8003 11.595 10.9256 12.1875 10.2544Z"
                  fill="#E3D4C2"/>
              <path
                  d="M2.8125 6.5625C2.8125 22.86 6.87844 25.1428 14.6709 28.0659C14.8772 28.1419 15.1078 28.1484 15.3291 28.0659C23.1216 25.1428 27.1875 22.86 27.1875 6.5625C27.1875 6.045 26.7675 5.625 26.25 5.625C22.6059 5.625 19.1963 4.48312 15.5203 2.0325C15.2053 1.8225 14.7956 1.8225 14.4806 2.0325C10.8038 4.48312 7.39406 5.625 3.75 5.625C3.2325 5.625 2.8125 6.045 2.8125 6.5625ZM15 3.93187C18.4519 6.13312 21.8381 7.29844 25.3078 7.47563C25.1653 22.1306 21.5203 23.7366 15 26.1862C8.47969 23.7366 4.83469 22.1306 4.69219 7.47563C8.16188 7.29844 11.5481 6.13312 15 3.93187Z"
                  fill="#E3D4C2"/>
            </svg>
            <h5>Goede en nette werkwijze</h5>
            <p>Als timmerman garandeer ik een goede en nette werkwijze, waarbij ik zorg voor een georganiseerde en opgeruimde werkomgeving.</p>
          </div>
          <div class="single-item">
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M31.8602 15.7633L30.9464 10.2744C30.7732 9.24588 29.8924 8.5 28.8501 8.5H23.375V6.375C23.375 5.20306 22.422 4.25 21.25 4.25H12.75C11.5781 4.25 10.625 5.20306 10.625 6.375V8.5H5.14996C4.10871 8.5 3.22683 9.24587 3.05365 10.2765L2.1399 15.7633C2.12502 15.8493 2.12077 15.9386 2.12927 16.0257L3.10996 27.8014C3.20133 28.8936 4.13102 29.75 5.22752 29.75H28.7725C29.869 29.75 30.7987 28.8936 30.889 27.8014L31.8708 16.0257C31.8793 15.9386 31.875 15.8493 31.8602 15.7633ZM12.75 6.375H21.25V8.5H12.75V6.375ZM5.14996 10.625H28.8511L29.5588 14.875H26.5625C26.5625 14.2874 26.0876 13.8125 25.5 13.8125C24.9125 13.8125 24.4375 14.2874 24.4375 14.875H9.56252C9.56252 14.2874 9.08758 13.8125 8.50002 13.8125C7.91246 13.8125 7.43752 14.2874 7.43752 14.875H4.44127L5.14996 10.625ZM28.7715 27.625H5.22752L4.34246 17H7.43752C7.43752 17.5876 7.91246 18.0625 8.50002 18.0625C9.08758 18.0625 9.56252 17.5876 9.56252 17H24.4375C24.4375 17.5876 24.9125 18.0625 25.5 18.0625C26.0876 18.0625 26.5625 17.5876 26.5625 17H29.6576L28.7715 27.625Z"
                  fill="#E3D4C2"/>
              <path
                  d="M21.4944 21.3498C21.3392 21.4869 21.25 21.8386 21.25 22.3125C21.25 23.6385 21.7844 23.5301 23.0754 23.1529C23.6459 22.9829 24.2292 23.3091 24.3939 23.8722C24.5597 24.4353 24.2377 25.0261 23.6746 25.1908C20.9387 25.9983 19.6467 25.0272 19.2546 23.375H14.7443C14.3469 25.0527 13.0337 25.9908 10.3243 25.1908C9.76224 25.0261 9.43924 24.4353 9.60499 23.8722C9.76967 23.3102 10.3551 22.9829 10.9235 23.1529C11.3507 23.2772 12.1847 23.5588 12.5035 23.2751C12.6607 23.138 12.75 22.7863 12.75 22.3125C12.75 21.8386 12.6607 21.4869 12.5056 21.3498C12.1858 21.0662 11.3422 21.3509 10.9257 21.472C10.3583 21.6431 9.77286 21.3158 9.60711 20.7527C9.44136 20.1896 9.7633 19.5988 10.3264 19.4342C13.0698 18.6245 14.3554 19.6031 14.7464 21.25H19.2567C19.6541 19.5723 20.9674 18.6341 23.6767 19.4342C24.2388 19.5988 24.5618 20.1896 24.396 20.7527C24.2314 21.3148 23.647 21.642 23.0775 21.472C22.6482 21.3477 21.8142 21.0662 21.4944 21.3498Z"
                  fill="#E3D4C2"/>
            </svg>
            <h5>Klanttevredenheid</h5>
            <p>Jouw tevredenheid staat bij mij voorop. Ik hecht veel waarde aan klanten contact en denk graag met je mee.</p>
          </div>
        </div>
      </div>
    </section>
    <section class="social-media">
      <div class="container">
        <div class="social-heading">
          <div class="left-side">
            <h4>Projecten </h4>
          </div>
          <div class="right-side" data-aos="fade-left" data-aos-duration="1200">
            <router-link to="/projecten">Bekijk meer</router-link>
          </div>
        </div>
        <div class="social-content" data-aos="fade-up" data-aos-duration="1200">
          <div class="div1">
            <img src="/images/keuken-bas-van-loo6.webp" alt="Van Loo Keuken">
          </div>
          <div class="div2">
            <img src="/images/keuken-bas-van-loo4.webp" alt="Van Loo Keuken">
          </div>
          <div class="div3">
            <img src="/images/keuken-bas-van-loo5.webp" alt="Van Loo Keuken">
          </div>
          <div class="div4">
            <img src="/images/keuken-bas-van-loo3.webp" alt="Van Loo Keuken">
          </div>
          <div class="div5">
            <img src="/images/keuken-bas-van-loo1.webp" alt="Van Loo Keuken">
          </div>
          <div class="div6">
            <img src="/images/keuken-bas-van-loo7.webp" alt="Van Loo Keuken">
          </div>
          <div class="div7">
            <img src="/images/keuken-bas-van-loo8.webp" alt="Van Loo Keuken">
          </div>
        </div>
      </div>
    </section>
    <section class="text-image" style="flex-direction: row-reverse">
      <div class="container">
        <div class="text-side" data-aos="fade-right" data-aos-duration="1200">
          <h1 class="page-title"><b style="font-weight: 800">Expert</b><br>voor meer dan 15 jaar</h1>
          <span class="stripe"></span>
          <p>Met passie en precisie creëer ik unieke meesterwerken. Of het nu gaat om op maat gemaakte meubels of
            prachtige houten constructies, ik breng jouw visie tot leven.
            <br><br>
            Met mijn jarenlange ervaring en oog voor
            kwaliteit, garandeer ik vakwerk. Ik ben pas tevreden als jij dat ook bent.</p>
        </div>
        <div class="img-side">
          <img src="/images/keuken-bas-van-loo3.webp" style="border-radius: 20px 40px; max-height: 500px"
               alt="Van Loo Keuken">
        </div>
      </div>
    </section>
    <br>
  </div>
</template>

<style lang="scss" scoped>

$blue: #475023;
$blue-d2: #C2A484;
$coal: #E3D4C2;

.introduction-image {
  img {
    width: 100%;
    height: 700px;
    object-fit: cover;
  }

  .image-overlay {
    position: absolute;
    background-color: $coal;
    top: 300px;
    right: 50px;
    width: 30vw;
    height: fit-content;
    padding: 40px 60px;
    border-top: $blue 5px solid;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    @media (max-width: 1150px) {
      width: 100%;
      max-width: 80vw;
      padding: 40px;
      top: 415px;
      left: 20px;
    }

    h1 {
      margin-bottom: 20px;
    }

    p {
      font-size: 18px;
    }
  }
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 20px;
  margin-top: 100px;
  @media (max-width: 1150px) {
    grid-template-columns: 1fr;
  }

  img {
    width: 100%
  }
}

.landing {
  .hero-banner-div {
    position: relative;
    @media (max-width: 600px) {
      height: 300px;
    }

    .hero-banner {
      width: 100%;
      margin-top: -100px;
      min-height: 100%;
      object-fit: cover;
      @media (max-width: 750px) {
        margin-top: 20px;
      }
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(34, 34, 34, 0.4);
    }
  }

  .hero-image {
    img {
      max-width: 600px;
      border-radius: 8px;
    }
  }

  .hero-navigator {
    background-color: white;
    width: 100%;
    margin-top: -200px;
    position: relative;
    padding: 45px 60px 60px;
    box-shadow: 0 4px 4px rgba(197, 197, 197, 0.25);

    @media (max-width: 1450px) {
      max-width: 90vw;
      margin-top: -170px;
    }

    @media (max-width: 1200px) {
      margin-top: -100px;
    }

    @media (max-width: 1000px) {
      max-width: 95vw;
      margin-top: -70px;
    }

    @media (max-width: 900px) {
      margin-top: 0;
    }

    @media (max-width: 750px) {
      padding: 25px;
      max-width: 90vw;
      margin-top: -60px;
    }


    .headline {
      h1 {
      }

      p {
        margin-bottom: 15px;
        color: #656565;

        @media (max-width: 750px) {
          margin-top: 20px;
          font-size: 20px;
        }

      }

      hr {
        border: none;
        background-color: $blue;
        width: 40px;
        height: 3px;
        margin: 0;
      }
    }

    .navigators {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      gap: 30px;
      height: 230px;
      position: relative;
      @media (max-width: 1450px) {
        height: 180px;
      }

      @media (max-width: 1200px) {
        height: 150px;
      }

      @media (max-width: 750px) {
        display: grid;
        height: unset;
        .different-navigator {
          height: 200px !important;
        }
      }


      .different-navigator {
        &::before {
          clip-path: polygon(88% 0, 100% 17%, 100% 100%, 0 100%, 0 0);
          background: #222222 !important;
        }

        &::after {
          clip-path: polygon(88% 0, 100% 17%, 100% 100%, 0 100%, 0 0);
        }
      }

      a {
        flex: 0 1 100%;
        cursor: default;
      }

      .single-navigator {
        width: 100%;
        height: 100%;
        max-width: 100%;
        position: relative;
        z-index: 4;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          background: linear-gradient(47.13deg, rgba(30, 30, 30, 0.8) 0%, rgba(207, 215, 255, 0) 97.4%);
          z-index: 1;
        }

        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  .zekerheden {
    background-color: $blue-d2;

    .container {
      padding-top: 70px;
      padding-bottom: 70px;

      b {
        color: $coal;
        font-size: 16px;
      }

      h3 {
        margin-top: 10px;
        font-weight: 800;
        font-size: 42px;
        color: white;
      }

      .items-grid {
        margin-top: 50px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 60px 80px;
        @media (max-width: 950px) {
          grid-template-columns: 1fr;
        }

        .single-item {
          border-left: solid 2px $coal;
          min-height: 140px;
          height: auto;
          display: grid;
          align-items: stretch;
          padding-left: 15px;

          h5 {
            font-weight: 800;
            font-size: 22px;
            margin-top: 10px;
            color: white;
          }

          p {
            margin-top: -5px;
            color: white;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
  }

  .social-media {
    margin: 50px 0 100px;

    .container {
      .social-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 950px) {
          align-items: flex-start;
          flex-direction: column;
          gap: 20px;
        }

        a {
          text-decoration: none;
          font-family: 'Avenir LT Std', sans-serif;
        }

        .right-side {
          height: auto;

          a {
            align-items: center;
            padding: 12px 50px;
            font-weight: 700;
            font-size: 20px;
            color: white;
            background-color: $blue;
            transition: .25s ease;

            &:hover {
              background-color: darken($blue, 15%);
            }
          }
        }

        .left-side {
          a {
            color: #474747;
            font-size: 22px;
            font-weight: 500;
          }

          h4 {
            font-weight: 900;
            font-size: 42px;
            color: #222222;
          }
        }
      }

      .social-content {
        display: grid;
        margin-top: 70px;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(2, 1fr);
        @media (max-width: 950px) {
          display: block;
          height: unset;
          .div4, .div5, .div5, .div6, .div7, .div8 {
            display: none;
          }
        }
        height: 400px;
        gap: 35px;

        div {
          background: rgba(217, 217, 217, 0.6);
          overflow: hidden;
        }

        img {
          width: 100%;
          margin: auto;
          object-fit: cover;
          height: 100%;
        }
      }

      .div1 {
        grid-area: 1 / 1 / 2 / 2;
      }

      .div2 {
        grid-area: 2 / 1 / 3 / 2;
      }

      .div3 {
        grid-area: 1 / 2 / 3 / 4;
      }

      .div4 {
        grid-area: 1 / 4 / 2 / 5;
      }

      .div5 {
        grid-area: 1 / 5 / 2 / 6;
      }

      .div6 {
        grid-area: 2 / 5 / 3 / 6;
      }

      .div7 {
        grid-area: 2 / 4 / 3 / 5;
      }
    }
  }

  .faq-section {
    .total-faq-headline {
      padding: 40px 0;
      background-color: $blue;
      margin-bottom: 50px;

      .container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .pill {
          width: fit-content;
          background-color: #DEEBFF;
          color: $blue;
          font-weight: 800;
          font-size: 16px;
          margin-bottom: 20px;
          cursor: default;
          border: none;
          padding: 10px 16px 8px;
          border-radius: 28px;
          font-family: 'Avenir LT Std', sans-serif;
        }

        h3, p {
          color: white;
        }

        h3 {
          font-size: 26px;
        }

        p {
          margin-top: 15px;
          font-family: 'Urbane', sans-serif;
          font-weight: 500;
          font-size: 16px;
        }
      }
    }

    .faq-content {
      .container {
        display: flex;
        flex-direction: column;
        max-width: 900px;

        .single-faq-item {
          cursor: pointer;
          overflow: hidden;
          border-top: solid 1px #ECEFF1;
          padding: 40px 0;

          .faq-headline {
            display: flex;
            justify-content: space-between;
            padding: 16px;

            h4 {
              font-size: 28px;
              font-weight: 600;
              @media (max-width: 700px) {
                font-size: 24px;
              }
            }

            svg {
              transition: .3s;
              transform: rotate(90deg);
              margin-right: 15px;
            }
          }

          .faq-content {
            font-size: 18px;
            font-weight: 300;
            padding: 0;
            height: 0;
            opacity: 0;
            transition: all .2s ease;
            overflow: hidden;
          }
        }

        .active-faq {
          .faq-headline {
            svg {
              transform: rotate(-90deg);
            }
          }

          .faq-content {
            height: auto;
            padding: 0 16px;
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>

<script>

// @ is an alias to /src
import AOS from "aos";

import TextImage from '@/components/TextImage.vue'
import Voordelen from "@/components/Voordelen";

export default {
  name: 'HomeView',
  components: {
    Voordelen,
    TextImage
  },
  data() {
    return {
      showedFaq: false,
    }
  },
  created() {
    // window.scrollTo(0, 0)
  },
  methods: {
    toggleFaq(id) {
      console.log(id)
      let selectedFaq = document.getElementById('faq-' + id)

      if (selectedFaq.classList.contains('active-faq')) {
        selectedFaq.classList.remove('active-faq')
      } else {
        selectedFaq.classList.add('active-faq')
      }

      this.showedFaq = !this.showedFaq
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    AOS.init();
  }
}
</script>
