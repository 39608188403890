<template>
  <div class="footer">
    <div class="contact-box">
      <h3>Neem nu contact op met Van Loo Keukens!</h3>
    </div>
    <div class="information-box">
      <div class="container">
        <div class="single-block">
          <h6>Locatie</h6>
          <a href="https://goo.gl/maps/Dyd3kiSjxrNcwuZZA" target="_blank">Ottenweg 9 <br>
            Oldebroek, Gelderland</a>
        </div>
        <div class="single-block">
          <h6>Direct contact</h6>
          <div class="social-icons">
            <a href="https://wa.me/+31651111431" target="_blank">
              <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_38_364)">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M37.5155 6.39467C33.3978 2.27333 27.9235 0.00183333 22.0917 0C10.0742 0 0.293326 9.779 0.287826 21.802C0.285992 25.6447 1.29066 29.3957 3.19916 32.703L0.104492 44L11.6618 40.9677C14.8463 42.7057 18.4323 43.6205 22.0807 43.6223H22.0898C34.1055 43.6223 43.8882 33.8415 43.8937 21.8185C43.8973 15.994 41.6313 10.5142 37.5155 6.39467ZM22.0917 39.9392H22.0843C18.832 39.9392 15.6438 39.0647 12.8608 37.4128L12.199 37.0205L5.34049 38.819L7.17199 32.131L6.74116 31.4453C4.92616 28.5597 3.96916 25.2248 3.97099 21.802C3.97466 11.8103 12.1055 3.68133 22.1008 3.68133C26.9408 3.68133 31.4912 5.56967 34.9122 8.99433C38.3332 12.4208 40.216 16.973 40.2142 21.8148C40.2087 31.8102 32.0797 39.9392 22.0917 39.9392ZM32.032 26.367C31.4875 26.0938 28.809 24.7757 28.3085 24.5942C27.8098 24.4127 27.4468 24.321 27.082 24.8655C26.7172 25.41 25.6758 26.6383 25.3568 27.0032C25.0397 27.3662 24.7207 27.412 24.1762 27.1388C23.6317 26.8657 21.8753 26.2918 19.7945 24.4347C18.1757 22.99 17.0812 21.2062 16.764 20.6598C16.4468 20.1135 16.731 19.8202 17.0023 19.5488C17.248 19.305 17.5468 18.9127 17.82 18.5937C18.095 18.2783 18.1848 18.051 18.3682 17.6862C18.5497 17.3232 18.4598 17.0042 18.3223 16.731C18.1848 16.4597 17.0958 13.7775 16.643 12.6867C16.2012 11.6233 15.752 11.7682 15.4165 11.7517C15.0993 11.7352 14.7363 11.7333 14.3715 11.7333C14.0085 11.7333 13.4182 11.869 12.9195 12.4153C12.4208 12.9617 11.0128 14.2798 11.0128 16.9602C11.0128 19.6423 12.9653 22.2328 13.2367 22.5958C13.508 22.9588 17.0775 28.4625 22.5427 30.822C23.8425 31.383 24.8582 31.7185 25.6483 31.9697C26.9537 32.384 28.1417 32.3253 29.0803 32.186C30.1272 32.0302 32.3033 30.8678 32.758 29.5955C33.2127 28.3232 33.2127 27.2305 33.0752 27.005C32.9395 26.7758 32.5765 26.6402 32.032 26.367Z"
                        fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_38_364">
                    <rect width="44" height="44" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
        </div>
        <div class="single-block">
          <h6>Bereikbaar via:</h6>
          <a href="mailto:basvanloo1@hotmail.com" target="_blank">Basvanloo1@hotmail.com</a>
          <a href="tel:0683244437" target="_blank">06 51111431</a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

$gold: #C2A484;
$gold-d2: #E3D4C2;
$coal: #475023;


.footer {
  margin-top: 120px;
  width: 100%;

  .contact-box {
    width: 100%;
    max-width: 900px;
    height: 200px;
    margin: -100px auto auto;
    position: relative;
    background-color: #475023;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(80px);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 60px 20px;
    h3 {
      font-size: 38px;
      color: $gold-d2;
      @media (max-width: 700px) {
        font-size: 28px;
      }
    }
  }
  .information-box {
    background-color: #C2A484;
    padding: 150px 0 50px;
    @media (max-width: 1100px) {
      padding: 150px 0;
    }
    .container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 30px;
      justify-items: stretch;
      @media (max-width: 1100px) {
        grid-template-columns: 1fr;
        gap: 40px;
      }

      .single-block {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
        .social-icons {
          display: flex;
          gap: 30px;
        }

        h6, p, a {
          text-align: center;
          font-family: 'Avenir LT Std', sans-serif;
          color: white;
        }

        h6 {
          font-size: 40px;
          margin-bottom: 10px;
          @media (max-width: 700px) {
          font-size: 35px;
            margin-bottom: 0;
          }
        }

        a {
          opacity: .7;
          transition: .3s;
          font-size: 30px;
          line-height: 35px;
          text-decoration: none;
          @media (max-width: 700px) {
            font-size: 25px;
            line-height: 30px;
          }
          &:hover {
            opacity: 1;
          }
        }

        svg {
          cursor: pointer;
          transition: .3s;

          &:hover {
            * {
              opacity: 1;
            }
          }

          * {
            cursor: pointer;
            fill: white;
            transition: .3s;
            opacity: .8;
          }
        }
      }
    }
  }
}
</style>

<script>
export default {
  name: 'FooterBar',
}
</script>
